<template>
    <pt-popup
        close-btn-pos="inline"
        custom-height="448px"
        custom-class="client-popup"
        :hide-header="true"
        :hide-footer="true"
        v-if="signinSuccessful && loaded"
        @closePopup="handlePopupCloseClick"
    >
        <div class="client-popup__container" slot="content">
            <pt-icon
                icon="icon-gtm"
                :icon-style="{
                    width: '48px',
                    height: '48px'
                }"
            ></pt-icon>
            <h1>{{ $t('setup.record_gtm') }}</h1>

            <p>{{ $t('setup.select_account') }}</p>
            <el-select
                class="select"
                size="small"
                :placeholder="$t('common.select_placeholder')"
                v-model="currentAccountId"
                @change="handleAccountChange"
            >
                <el-option
                    v-for="item in accountList"
                    :key="item.accountId"
                    :label="item.name"
                    :value="item.accountId"
                ></el-option>
            </el-select>

            <p>{{ $t('setup.select_container') }}</p>
            <el-select
                class="select"
                size="small"
                :placeholder="$t('common.select_placeholder')"
                v-model="currentContainerId"
                @change="handleContainerChange"
            >
                <el-option
                    v-for="item in containerList"
                    :key="item.containerId"
                    :label="item.name"
                    :value="item.containerId"
                ></el-option>
            </el-select>

            <template v-if="this.workspaceList.length > 1">
                <p>{{ $t('setup.select_workspace') }}</p>
                <el-select
                    class="select"
                    size="small"
                    :placeholder="$t('common.select_placeholder')"
                    v-model="currentWorkspaceId"
                    @change="handleWorkspaceChange"
                >
                    <el-option
                        v-for="item in workspaceList"
                        :key="item.workspaceId"
                        :label="item.name"
                        :value="item.workspaceId"
                    ></el-option>
                </el-select>
            </template>

            <pt-button
                size="normal"
                fullfilled
                :disabled="!currentContainerId"
                :loading="loading"
                @click="handleCreateClick"
            >{{ $t('setup.publish') }}</pt-button>
        </div>
    </pt-popup>
</template>

<script>
require('@/assets/lib/google/api.js');
import { mapGetters } from 'vuex';
import codeConfig from './code.config';
import { supplant } from '@/common/utils/string.utils';
import baseConfig from '@/common/configs/base.config';

//注：Chrome在无痕模式下默认会禁用第三方cookie(可在设置中修改)，会导致授权不成功。
export default {
    name: 'GtmClient',

    data() {
        const clientUrl = 'https://content.googleapis.com/discovery/v1/apis/tagmanager/v2/rest';
        // const clientUrl = 'https://content.googleapis.com/discovery/v1/apis/tagmanager/v1/rest';
        const scopes = [
            'https://www.googleapis.com/auth/tagmanager.manage.accounts',
            'https://www.googleapis.com/auth/tagmanager.edit.containers',
            'https://www.googleapis.com/auth/tagmanager.publish',
            'https://www.googleapis.com/auth/tagmanager.edit.containerversions'
        ].join(' ');
        return {
            scopes,
            clientUrl,
            signinSuccessful: false,
            createTagFailed: false,
            loaded: false,
            loading: false,
            firingTriggerId: '2147479553', //GA all pages固定变量
            accountList: [],
            containerList: [],
            workspaceList: [],
            versionList: [],
            currentAccountId: null,
            currentContainerId: null,
            currentWorkspaceId: null
        };
    },

    computed: {
        ...mapGetters('main', ['profileInfo']),

        installCode() {
            const { domain, sid } = this.profileInfo || {};
            const { pteInstallCode } = codeConfig;
            return supplant(pteInstallCode, { domain, sid });
        }
    },

    mounted() {
        console.log('gapi', window.gapi);
        this.initGoogleClient();
    },

    methods: {
        //API: https://developers.google.com/tag-manager/api/v2/reference
        initGoogleClient() {
            const _self = this;
            window.gapi &&
                window.gapi.load('client:auth2', function () {
                    window.gapi.auth2
                        .init({
                            client_id: baseConfig.GOOGLE_CLIENT_ID
                        })
                        .then(
                            () => {
                                _self.$emit('init');
                            },
                            err => {
                                console.log('google client init error', err);
                                _self.$emit('error', 'init');
                            }
                        );
                });
        },

        //API: https://developers.google.com/identity/sign-in/web/reference
        authenticate() {
            return window.gapi.auth2
                .getAuthInstance()
                .signIn({
                    scope: this.scopes
                })
                .then(
                    () => {
                        this.signinSuccessful = true;
                    },
                    () => {
                        this.$emit('error', 'auth');
                        this.signinSuccessful = false;
                    }
                );
        },

        loadClient() {
            window.gapi.client.setApiKey(baseConfig.GOOGLE_API_KEY);
            return window.gapi.client.load(this.clientUrl).then(
                () => {
                    this.loaded = true;
                    this.fetchAccountList();
                },
                () => {
                    this.$emit('error', 'load');
                    this.loaded = false;
                }
            );
        },

        fetchAccountList() {
            return window.gapi.client.tagmanager.accounts.list({}).then(
                res => {
                    this.accountList = (res.result || {}).account || [];
                },
                err => {
                    this.accountList = null;
                    this.$emit('error', 'fetchAccounts');
                }
            );
        },

        fetchContainerList(accountId) {
            return window.gapi.client.tagmanager.accounts.containers
                .list({
                    parent: `accounts/${accountId}`
                })
                .then(
                    res => {
                        this.containerList = (res.result || {}).container || [];
                    },
                    err => {
                        this.containerList = null;
                        this.$emit('error', 'fetchContainers');
                    }
                );
        },

        fetchWorkspaceList(accountId, containerId) {
            return window.gapi.client.tagmanager.accounts.containers.workspaces
                .list({
                    parent: `accounts/${accountId}/containers/${containerId}`
                })
                .then(
                    res => {
                        this.workspaceList = (res.result || {}).workspace || [];
                        this.workspaceList.length > 0 && (this.currentWorkspaceId = this.workspaceList[0].workspaceId);
                    },
                    err => {
                        this.workspaceList = null;
                        this.$emit('error', 'fetchWorkspaces');
                    }
                );
        },

        createTag(accountId, containerId, workspaceId) {
            this.loading = true;
            return window.gapi.client.tagmanager.accounts.containers.workspaces.tags
                .create({
                    parent: `accounts/${accountId}/containers/${containerId}/workspaces/${workspaceId}`,
                    resource: {
                        name: 'Ptengine Script',
                        type: 'html',
                        parameter: [
                            {
                                type: 'template',
                                key: 'html',
                                value: this.installCode
                            },
                            {
                                type: 'boolean',
                                key: 'supportDocumentWrite',
                                value: 'false'
                            }
                        ],
                        firingTriggerId: [this.firingTriggerId]
                    }
                })
                .then(
                    res => {
                        this.createTagFailed = false;
                        this.versionCreate(accountId, containerId, workspaceId);
                    },
                    err => {
                        this.createTagFailed = true;
                        this.loading = false;
                        this.$emit('error', 'createTag');
                    }
                );
        },

        versionCreate(accountId, containerId, workspaceId) {
            return gapi.client.tagmanager.accounts.containers.workspaces
                .create_version({
                    path: `accounts/${accountId}/containers/${containerId}/workspaces/${workspaceId}`,
                    resource: {}
                })
                .then(
                    res => {
                        this.currentVersion = res.result.containerVersion;
                        this.versionPublish(accountId, containerId, this.currentVersion.containerVersionId);
                    },
                    err => {
                        this.loading = false;
                        this.$emit('error', 'createVersion');
                    }
                );
        },

        versionPublish(accountId, containerId, containerVersionId) {
            return gapi.client.tagmanager.accounts.containers.versions
                .publish({
                    path: `accounts/${accountId}/containers/${containerId}/versions/${containerVersionId}`
                })
                .then(
                    res => {
                        this.$emit('createFinish');
                    },
                    err => {
                        this.loading = false;
                        this.$emit('error', 'publish');
                    }
                );
        },

        handleSigninClick() {
            window.gapi && this.authenticate().then(this.loadClient);
        },

        handleCreateClick() {
            this.createTag(this.currentAccountId, this.currentContainerId, this.currentWorkspaceId);
        },

        handleAccountChange(accountId) {
            this.currentAccountId = accountId;
            this.fetchContainerList(accountId);
        },

        handleContainerChange(containerId) {
            this.currentContainerId = containerId;
            this.fetchWorkspaceList(this.currentAccountId, containerId);
        },

        handleWorkspaceChange(workspaceId) {
            this.currentWorkspaceId = workspaceId;
        },

        handlePopupCloseClick() {
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss">
.client-popup {
    max-width: 520px !important;

    &__container {
        padding-bottom: 48px;

        svg {
            display: block;
            margin: 0 auto;
        }

        h1 {
            color: #081e42;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 27px;
            text-align: center;
            margin: 16px 0 36px;
        }

        p {
            color: #081e42;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            margin: 20px 0 8px;
        }

        button {
            margin-top: 40px;
        }

        .select {
            width: 100%;
        }
    }
}
</style>
