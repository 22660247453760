import i18n from '@assets/i18n';

const eventInstallCode = `ptengine.track("check_out", {
    product_category: "Clothing",
    product_pricing: "1293"
});`;

const eventInstallCodeHTML = `ptengine.track("<b>check_out</b>", {
    product_category: <span>"Clothing"</span>,
    product_pricing: <span>"1293"</span>
});`;

const userInstallCode = `ptengine.identify("12148", {
    //${i18n.t('data_settings.install_user_uid')}
    email: "jsmith@example.com", //${i18n.t('data_settings.install_user_email')}
    phone: "000-000-0000",
    gender: "Male",
    created_at: "2011-03-16 16:53:54",
    credits: 150
})`;

const userInstallCodeHTML = `ptengine.identify("<b>12148</b>", {
    //${i18n.t('data_settings.install_user_uid')}
    email: <span>"jsmith@example.com"</span>, //${i18n.t('data_settings.install_user_email')}
    phone: <span>"000-0000-0000"</span>,
    gender: <span>"Male"</span>,
    created_at: <span>"2011-03-16 16:53:54"</span>,
    credits: <span>150</span>
})`;

const ptxInstallCode = `<!-- Ptengine -->
<script>(function(w, d, p, x) {if (!p.__ver) {w.ptengine = p;p.__ver = '1.0.0';p.__sid = x;var l = ['track', 'page', 'identify'];for (var i = 0; i < l.length; i++) {var e = l[i];p[e] = function() {p.push([e].concat(Array.prototype.slice.call(arguments, 0)));};}var s = d.getElementsByTagName('script')[0];var t = d.createElement('script');t.type = 'text/javascript';t.src = 'https://xjs.ptengine.jp/pttag.js?id=' + x;s.parentNode.insertBefore(t, s);}})(window, document, window.ptengine || [], '{sid}');<\/script>
<!-- End Ptengine -->`;

const ptxInstallCodeHTML = `&lt;!-- Ptengine --&gt
<i>&lt;script&gt;(function(w, d, p, x) {if (!p.__ver) {w.ptengine = p;p.__ver = '1.0.0';p.__sid = x;var l = ['track', 'page', 'identify'];for (var i = 0; i < l.length; i++) {var e = l[i];p[e] = function() {p.push([e].concat(Array.prototype.slice.call(arguments, 0)));};}var s = d.getElementsByTagName('script')[0];var t = d.createElement('script');t.type = 'text/javascript';t.src = 'https://xjs.ptengine.jp/pttag.js?id=' + x;s.parentNode.insertBefore(t, s);}})(window, document, window.ptengine || [], '{sid}');&lt;/script&gt</i>
&lt;!-- End Ptengine --&gt`;

const pteInstallCode = `<!-- Ptengine Tag -->
<script src="https://js.ptengine.jp/{sid}.js"></script>
<!-- End Ptengine Tag -->`;

const pteInstallCodeHTML = `<!-- Ptengine Tag -->
<script src="https://js.ptengine.jp/{sid}.js">
</script>
<!-- End Ptengine Tag -->`;

export default {
    ptxInstallCode,
    ptxInstallCodeHTML,
    pteInstallCode,
    pteInstallCodeHTML,
    userInstallCode,
    userInstallCodeHTML,
    eventInstallCode,
    eventInstallCodeHTML
};
